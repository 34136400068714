import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router-dom";

// styles
import "./landingpagestyle.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const LandingPageComponent = (props) => {
  let history = useHistory();
  const [season, setSeasons] = useState("");
  const [age, setAge] = useState("");
  const [seasonDistrictsData, setSeasonDistrictsData] = useState([]);
  const [seasonIdOption, setSeasonIdOption] = useState([]);
  const [selectIdOption, setSelectIdOption] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    fetch(
      "https://media.rgi-report.satsure.co/reliance/regions/rgi_season_districts.json"
    )
      .then((response) => response.json())
      .then((data) => {
        var dataArr = data;
        setSeasonDistrictsData(dataArr);
        var arr = [];
        for (let i in dataArr) {
          var obj = {
            text: `${i}`,
            value: i,
          };
          arr.push(obj);
        }
        setSeasonIdOption(arr);
      });
  }, []);

  const handleChangeSeason = (e) => {
    setSeasons(e.target.value);
    var findSeason = seasonDistrictsData[e.target.value];
    getDistictList(findSeason.regions);
  };

  const getDistictList = (seasonValue) => {
    var seasonValueArr = [];
    for (let i in seasonValue) {
      var obj = {
        text: `${seasonValue[i].state} - ${seasonValue[i].district}`,
        value: seasonValue[i].regionId,
      };
      seasonValueArr.push(obj);
    }
    setSelectIdOption(seasonValueArr);
  };

  const handleChange = (event) => {
    history.replace(`/pdf-layout/${event.target.value}`);
  };

  const logout = () => {
    localStorage.clear();
    history.replace("/", { replace: true });
  };

  return (
    <div className="header-sections">
      <div>
        <h2>Select Season</h2>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Season</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={season}
            onChange={(e) => {
              handleChangeSeason(e);
            }}
            label="Id"
          >
            {seasonIdOption.map((data, key) => {
              return <MenuItem value={data.value}> {data.text} </MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      <div>
        <h2>Select District</h2>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            District
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={age}
            onChange={handleChange}
            label="Id"
          >
            {selectIdOption.map((data, key) => {
              return <MenuItem value={data.value}> {data.text} </MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      <div className="logout">
        <button onClick={logout}>Logout</button>
      </div>
    </div>
  );
};

export default LandingPageComponent;
