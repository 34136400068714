import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//CSS
import "./index.css";

//IMAGES
import passwordshow from "../../assets/images/Eye.svg";
import passwordhide from "../../assets/images/Eye-closed.svg";
import satsureLogo from "../../assets/images/Satsure-Logo.png";

const credentials = [
  {
    userName: "dev_user",
    password: "dev_user@123",
    user_type: "internal",
  },
  {
    userName: "rgi_user",
    password: "rgi@123",
    user_type: "external",
  },
];

const Login = () => {
  const navigate = useHistory();

  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const [hideShowPwd, setHideShowPwd] = useState(true);
  const getUserName = (e) => {
    setUserName(e);
  };

  const getUserPassword = (e) => {
    setUserPassword(e);
  };

  const loginSubmit = () => {
    const user = credentials.find((item) => {
      if (item.userName === userName && item.password === userPassword) {
        return item;
      }
    });

    if (user && user.userName) {
      localStorage.setItem("user-data", JSON.stringify(user));
      navigate.push("/landing", { replace: true });
    }
  };

  return (
    <div className="login-container">
      <div className="login-sections">
        <div className="login-header">
          <div className="header-satsurelogo">
            <img src={satsureLogo} alt="Satsure Logo" />
          </div>
          <p>District Level Report on Crop Health Monitoring</p>
        </div>
        <div className="login-card">
          <div className="form-group">
            <label>
              User Name <sup>*</sup>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your User Name"
              value={userName}
              onChange={(e) => getUserName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>
              Password <sup>*</sup>
            </label>
            <input
              type={hideShowPwd ? "password" : "text"}
              className="form-control"
              placeholder="Enter your Password"
              value={userPassword}
              onChange={(e) => getUserPassword(e.target.value)}
            />{" "}
            <img
              src={hideShowPwd ? passwordhide : passwordshow}
              alt="loginLogo"
              onClick={() => setHideShowPwd((prev) => !prev)}
              className="password-eye"
            />
          </div>
          <div className="login-button">
            <button onClick={loginSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
