import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCVeeKQWGj2YlYveQTkJT-Ex22vj28VuQY",
  authDomain: "satsure-rgi.firebaseapp.com",
  databaseURL:
    "https://satsure-rgi-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "satsure-rgi",
  storageBucket: "satsure-rgi.appspot.com",
  messagingSenderId: "1081668623592",
  appId: "1:1081668623592:web:1a9fdfeb055023e36b7d5d",
  measurementId: "G-NEE449DCRZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const storage = getStorage(app);

export { database, storage };
